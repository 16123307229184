/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Hide the default radio button appearance */
input[type="radio"].checkbox-style {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid grey;
  border-radius: 4px; /* Make it square like a checkbox */
  background-color: #f0f0f0;
  cursor: pointer;
  position: relative;
}

/* Style the "checked" state to resemble a checkbox checkmark */
input[type="radio"].checkbox-style:checked {
  background-color: #007bff; /* Change color when checked */
  border-color: #007bff;
}

input[type="radio"].checkbox-style:checked::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 5px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}